import React, {useRef, useState} from "react";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {useFormik} from "formik";
import {classNames} from "primereact/utils";
import {Checkbox} from "primereact/checkbox";
import {userService} from "../../../../services/UserService";
import {Toast} from "primereact/toast";

interface RegisterFormInterface {
    first_name: string;
    email: string;
    password: string
    agreement: boolean
}

const RegisterPage = () => {

    const [formData, setFormData] = useState({});
    // @ts-ignore
    const isFormFieldValid = (name: any) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name: any) => {
        // @ts-ignore
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const toast = useRef(null);


    const formik = useFormik({
        initialValues: {
            first_name: '',
            email: '',
            password: '',
            agreement: false,
        },
        validate: (data: RegisterFormInterface) => {
            let errors: any = {};

            if (!data.first_name) {
                errors.first_name = 'Imię jest wymagane';
            } else if (data.first_name.length <= 2) {
                errors.first_name = 'Podane imię jest za krótkie';
            }

            if (!data.email) {
                errors.email = 'Adres e-mail jest wymagany.';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                errors.email = 'Niepoprawny format adresu e-mail';
            }

            if (!data.password) {
                errors.password = 'Hasło jest wymagane.';
            } else if (data.password.length <= 7) {
                errors.password = 'Wpisane hasło jest za krótkie';
            }

            if (!data.agreement) {
                errors.agreement = 'Tu!';
            }

            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            userService.create(data).then(() => {
                window.open('/login', '_self')
                formik.resetForm();
            }).catch(() => {
                // @ts-ignore
                toast.current.show({
                    severity: 'warn',
                    detail: 'Podany adres email posiada już zarejestrowane konto',
                    life: 5000
                });
            })
        }
    });

    return (
        <div className="loginFormContainer">
            <h1>Założ konto</h1>
            <Toast ref={toast}/>
            <div className="subContainer">
                <span>Masz już konto? <a href={'/login'}>Zaloguj się</a> </span>
            </div>

            <div className="formContainer">
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="formRow">
                            <span className="p-float-label">
                                <InputText id="firstNameField" name="first_name" value={formik.values.first_name}
                                           onChange={formik.handleChange}
                                           className={classNames({'p-invalid': isFormFieldValid('first_name')})}
                                           autoFocus/>
                                <label htmlFor="firstNameField"
                                       className={classNames({'p-error': isFormFieldValid('first_name')})}>Imię</label>
                            </span>
                        {getFormErrorMessage('first_name')}
                    </div>
                    <div className="formRow">
                            <span className="p-float-label">
                                <InputText id="emailField" name="email" value={formik.values.email}
                                           onChange={formik.handleChange}
                                           className={classNames({'p-invalid': isFormFieldValid('email')})}/>
                                <label htmlFor="emailField"
                                       className={classNames({'p-error': isFormFieldValid('email')})}>Email</label>
                            </span>
                        {getFormErrorMessage('email')}
                    </div>

                    <div className="formRow">
                            <span className="p-float-label">
                                <InputText id="passwordField" name="password" type="password"
                                           value={formik.values.password}
                                           onChange={formik.handleChange}
                                           className={classNames({'p-invalid': isFormFieldValid('password')})}/>
                                <label htmlFor="passwordField"
                                       className={classNames({'p-error': isFormFieldValid('password')})}>Hasło</label>
                            </span>
                        {getFormErrorMessage('password')}
                    </div>

                    <div className="formRow">
                        <div className="p-field-checkbox inline-flex align-items-start">
                            <Checkbox id="agreementField" name="agreement"
                                      value={formik.values.agreement}
                                      onChange={formik.handleChange}
                                      checked={formik.values.agreement}/>
                            <label htmlFor="agreementField"
                                   className={classNames({'p-error text-orange-500': isFormFieldValid('agreement')})}>
                                Wyrażam zgodę na przetwarzanie moich <br/> danych osobowych</label>

                        </div>
                    </div>

                    <div className="submitButton">
                        <Button type={"submit"} label="Zaloguj się"/>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default RegisterPage;