import {Redirect} from "react-router-dom";
import {useEffect} from "react";
import {userService} from "../../../../services/UserService";

export default function LogoutPage() {
    useEffect(() => {
        userService.logout();
    }, [])
    return (
        <Redirect to={{pathname: '/login'}}/>
    )

}