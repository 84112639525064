import React, {FunctionComponent} from 'react';
import './LoginModuleStyles.scss';

const LoginModule: FunctionComponent<any> = props => {
    return (
        <div className="min-h-screen grid m-0">
            <div className="col font-bold text-white border-round sectionImage">
                <div className="flex justify-content-center align-items-center">
                    <h1>PsieSpacerki.PL</h1>
                </div>
            </div>
            <div
                className="col inline-flex justify-content-center align-items-center font-bold text-black border-round p-0">
                {props.children}
            </div>
        </div>
    )
}

export default LoginModule;