import * as React from 'react';
import {Route} from 'react-router-dom';
import {Redirect, RouteProps} from 'react-router';
import {Page404} from "../pages/404Page";
import {userService} from "../services/UserService";

const redirectComponent = (rest: any) => () => <Redirect to={{pathname: '/login', state: {from: rest.location}}}/>;

export const PrivateRoute = ({component, ...rest}: RouteProps) => {
    const user = userService.getLoginUser(),
        routeComponent = user ? component : Page404;

    return (
        user
            ? <Route exact {...rest} component={routeComponent}/>
            : <Route exact {...rest} component={redirectComponent(rest)}/>
    );
};
