import {MapContainer, Marker, Popup, TileLayer, ZoomControl} from "react-leaflet";
import LocationInterface from "../../interfaces/LocationInterface";
import React, {useEffect, useState} from "react";
import LocationService from "../../services/LocationService";
import L from "leaflet";
import './MapPageStyles.scss'
import 'leaflet/dist/images/marker-icon.png';
import 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css'
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.js'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import PanelsContainer from "../../components/Panels/PanelsContainer/PanelsContainer";
import PlacePanel from "../../components/Panels/PlacePanel/PlacePanel";
import {ActionBar} from "../../components/ActionBar/ActionBar";
import {LocationFilterInterface} from "../../interfaces/LocationFilterInterface";
import MarkerClusterGroup from "react-leaflet-markercluster";

function MapPage() {
    const locationService = new LocationService();

    const [location, setLocation] = useState<LocationInterface | false>();
    const [locations, setLocations] = useState<LocationInterface[]>([]);
    const [clusterKey, setClusterKey] = useState<number>(Math.random());

    const [hasWater, setHasWater] = useState<boolean>(false);
    const [hasForest, setHasForest] = useState<boolean>(false);
    const [hasMeadow, setHasMeadow] = useState<boolean>(false);

    const filters: LocationFilterInterface = {
        hasForest: hasForest, hasMeadow: hasMeadow, hasWater: hasWater
    }

    L.Marker.prototype.options.icon = L.icon({
        iconSize: [25, 41],
        iconAnchor: [10, 41],
        popupAnchor: [2, -40],
        iconUrl: icon,
        shadowUrl: iconShadow
    });

    useEffect(() => {
        locationService.getLocations(filters).then(locations => {
            setLocations((locations as any).items);
            setClusterKey(Math.random());
        })
    }, [hasWater, hasMeadow, hasForest]);

    return (
        <div>
            <PanelsContainer>
                {location ? <PlacePanel setLocation={setLocation} location={location}/> : null}
            </PanelsContainer>

            <ActionBar hasForest={hasForest} hasWater={hasWater} hasMeadow={hasMeadow} setHasForest={setHasForest}
                       setHasMeadow={setHasMeadow} setHasWater={setHasWater} isLogged={true}/>


            <MapContainer
                zoomControl={false} id="mapRoot" center={[51.8904457, 19.8590717]} zoom={7}
                minZoom={3}
                maxZoom={18}
                scrollWheelZoom={true}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                <MarkerClusterGroup key={clusterKey}>
                    {locations.map((location: LocationInterface) => (
                        <Marker
                            key={location.id}
                            draggable={false}
                            position={[location.lat, location.lng]}
                            eventHandlers={{
                                click: () => {
                                    locationService.getLocation(location.id).then(location => {
                                        setLocation(location);
                                    })
                                },
                            }}>

                            <Popup>
                                {location.name}
                            </Popup>

                        </Marker>
                    ))}
                </MarkerClusterGroup>

                <ZoomControl position="bottomright"/>
            </MapContainer>
        </div>
    )
}


export default MapPage;