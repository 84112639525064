import {authHeader} from "./AuthHeader";
import {userService} from "../services/UserService";

// const SERVICE_URL: string = `${process.env.REACT_APP_API_SERVER}/api/rest/v1/`;
// const SERVICE_URL: string = `http://localhost:3001`;

const SERVICE_URL: string = `https://api.psiespacerki.pl`;

export enum REQUEST_METHOD {
    'GET' = 'GET',
    'POST' = 'POST',
    'PATCH' = 'PATCH',
    'DELETE' = 'DELETE'
}

interface RequestOptionsInterface {
    method?: REQUEST_METHOD;
    body?: object | string;
}

const getDefaultOptions = () => {
    return {
        headers: {'Content-Type': 'application/json', ...authHeader()},
        method: 'GET'
    };
};

export const sendFormDataRequest = async (url: string, file: any) => {
    const imageData = new FormData();
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || "") : null;

    file.files.map((image: any) => {
        imageData.append("files", image);
    })

    const config = {
        method: "POST",
        headers: {
            'Authorization': 'Bearer ' + user.token
        },
        body: imageData,
    };

    try {
        const req = await fetch(`${SERVICE_URL}${url}`, config);
        if (req.ok) {
            const res = await req.json();
            console.log(res.success)
        }
    } catch (err) {
        console.log(err);
    }
};

export const sendRequest = (url: string, options: RequestOptionsInterface = {}): Promise<any> => {
    if (options.body && typeof options.body === 'object') {
        options.body = JSON.stringify(options.body);
    }

    return fetch(`${SERVICE_URL}${url}`, {...getDefaultOptions(), ...options} as any)
        .then((response: Response) => {
            if (!response.ok) {
                switch (response.status) {
                    case 401:
                        return userService.refreshToken()
                            .then(() => sendRequest(url, options))
                            .catch(() => {
                                window.location.replace('/logout');
                            });
                }


                const parsedResponse: any = response.json();

                return parsedResponse.then((data: any) => Promise.reject(data));
            }

            if (response.status === 204) {
                return {};
            }

            return response.json();
        });
};