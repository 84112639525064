import './ControlContainerStyles.scss'
import {Checkbox} from 'primereact/checkbox';
import React, {useState} from "react";
import {Button} from "primereact/button";

interface ControlContainerInterface {
    setVisible: (state: boolean) => void
    setHasWater: (state: boolean) => void
    setHasForest: (state: boolean) => void
    setHasMeadow: (state: boolean) => void
    hasMeadow: boolean
    hasForest: boolean
    hasWater: boolean
}

const ControlContainer = (props: ControlContainerInterface) => {
    return (
        <div className={'controlContainer'}>
            <div className={'grid flex'}>
                <div className="col-4 singleCheckbox">
                    <Checkbox onChange={e => props.setHasWater(e.checked)} checked={props.hasWater}/>
                    <label htmlFor="cb1" className="p-checkbox-label">Woda</label>
                </div>
                <div className="col-4 singleCheckbox">
                    <Checkbox onChange={e => props.setHasMeadow(e.checked)}  checked={props.hasMeadow}/>
                    <label htmlFor="cb1" className="p-checkbox-label">Łąka</label>
                </div>
                <div className="col-4 singleCheckbox">
                    <Checkbox onChange={e => props.setHasForest(e.checked)}  checked={props.hasForest}/>
                    <label htmlFor="cb1" className="p-checkbox-label">Las</label>
                </div>
            </div>
            <div className={'actionBar flex justify-content-center'}>
                <Button label="Dodaj pinezkę" className="p-button-rounded p-button-success p-button-sm m-1" onClick={() => {
                    props.setVisible(true)
                }} />
            </div>
        </div>
    )
}

export default ControlContainer;