import React from 'react';
import './PanelsContainerStyles.scss'

export interface PanelsContainerProps  {
    children: React.ReactNode
}

export default function PanelsContainer(props: PanelsContainerProps) {
    return (
        <div className={'panelsContainer'}>
            {props.children}
        </div>
    )
}