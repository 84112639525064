import React, {useRef, useState} from "react";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {useFormik} from "formik";
import {classNames} from "primereact/utils";
import {userService} from "../../../../services/UserService";
import {Toast} from "primereact/toast";

interface LoginFormInterface {
    email: string;
    password: string
}

const LoginPage = () => {
    const [formData, setFormData] = useState<LoginFormInterface | any>({});
    const toast = useRef(null);

    // @ts-ignore
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    // @ts-ignore
    const getFormErrorMessage = (name) => {
        // @ts-ignore
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validate: (data: LoginFormInterface) => {
            let errors: any = {};

            if (!data.email) {
                errors.email = 'Adres e-mail jest wymagany.';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                errors.email = 'Niepoprawny format adresu e-mail';
            }

            if (!data.password) {
                errors.password = 'Hasło jest wymagane.';
            } else if (data.password.length <= 7) {
                errors.password = 'Wpisane hasło jest za krótkie';
            }

            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            userService.login(data.email, data.password).then(() => {
                window.open('/map', '_self')
                formik.resetForm();
            }).catch(() => {
                // @ts-ignore
                toast.current.show({
                    severity: 'warn',
                    detail: 'Niepoprawny login bądź hasło',
                    life: 5000
                });
            })
        }
    });

    return (
        <div className="loginFormContainer">
            <h1>Zaloguj się</h1>
            <Toast ref={toast}/>
            <div className="subContainer">
                <span>Jesteś tu nowy? <a href={'/register'}>Załóż konto</a> </span>
            </div>

            <div className="formContainer">
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="formRow">
                                <span className="p-float-label">
                                    <InputText id="email"
                                               name="email"
                                               value={formik.values.email}
                                               onChange={formik.handleChange}
                                               className={classNames({'p-invalid': isFormFieldValid('email')})}
                                               autoFocus/>
                                    <label htmlFor="email"
                                           className={classNames({'p-error': isFormFieldValid('email')})}>Email</label>
                                </span>
                        {getFormErrorMessage('email')}
                    </div>
                    <div className="formRow">
                                <span className="p-float-label">
                                    <InputText id="password"
                                               name="password"
                                               type="password"
                                               value={formik.values.password}
                                               onChange={formik.handleChange}
                                               className={classNames({'p-invalid': isFormFieldValid('password')})}/>
                                    <label htmlFor="password"
                                           className={classNames({'p-error': isFormFieldValid('password')})}>Hasło</label>
                                </span>
                        {getFormErrorMessage('password')}
                    </div>

                    <div className="forgotPasswordRow">
                        <a href={'/forgot'}>Nie pamiętasz hasła?</a>
                    </div>

                    <div className="submitButton">
                        <Button type="submit" label="Zaloguj się"/>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default LoginPage;