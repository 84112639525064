import React from 'react';
import './App.scss';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import {Redirect, Route, Router, Switch} from "react-router-dom";
import MapPage from './pages/MapPage/MapPage';
import useToken from "./hooks/useToken";
import {createBrowserHistory} from 'history';
import {PrivateRoute} from "./helpers";
import LoginModule from "./pages/LoginModule/LoginModule";
import LoginPage from "./pages/LoginModule/components/LoginPage/LoginPage";
import RegisterPage from "./pages/LoginModule/components/RegisterPage/RegisterPage";
import ForgotPage from "./pages/LoginModule/components/ForgotPage/ForgotPage";
import ResetPasswordPage from "./pages/LoginModule/components/ResetPasswordPage/ResetPasswordPage";
import LogoutPage from "./pages/LoginModule/components/LogoutPage/LogoutPage";

function App() {
    document.title = 'Psie Spacerki 🐕🐕🐕 | Znajdź fajne miejsca na spacer ze swoim czworonogiem!!'
    const {token, setToken} = useToken();

    return (
        <div>
            <Router history={createBrowserHistory()}>
                <Switch>
                    <Route path={'/login'}>
                        <LoginModule>
                            <LoginPage/>
                        </LoginModule>
                    </Route>
                    <Route path={'/register'}>
                        <LoginModule>
                            <RegisterPage/>
                        </LoginModule>
                    </Route>
                    <Route path={'/forgot'}>
                        <LoginModule>
                            <ForgotPage/>
                        </LoginModule>
                    </Route>
                    <Route path={'/reset-password/:token'}>
                        <LoginModule>
                            <ResetPasswordPage/>
                        </LoginModule>
                    </Route>
                    <Route path={'/logout'}>
                        <LogoutPage/>
                    </Route>
                    <Route path={'/register'} component={RegisterPage}/>
                    <Route path={'/remember'} component={ForgotPage}/>
                    {token ? <PrivateRoute path="/" component={MapPage}/> : <Redirect to={'/login'}/>}
                </Switch>
            </Router>
        </div>
    );
}

export default App;
