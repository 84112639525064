import React, {useRef, useState} from "react";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {useFormik} from "formik";
import {classNames} from "primereact/utils";
import {userService} from "../../../../services/UserService";
import {useParams} from "react-router-dom";
import {Toast} from "primereact/toast";

interface ForgotFormInterface {
    token: string;
    password: string;
    repeated_password: string;
}

const ResetPasswordPage = () => {
    let {token} = useParams<any>();
    const toast = useRef(null);

    const [formData, setFormData] = useState<ForgotFormInterface | any>({});

    // @ts-ignore
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    // @ts-ignore
    const getFormErrorMessage = (name) => {
        // @ts-ignore
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const formik = useFormik({
        initialValues: {
            token: '',
            password: '',
            repeated_password: '',
        },
        validate: (data: ForgotFormInterface) => {
            let errors: any = {};

            if (!data.password) {
                errors.password = 'Hasło jest wymagane';
            } else if (data.password.length <= 7) {
                errors.password = 'Hasło musi składać się z minimum 8 znaków';
            }

            if (data.password !== data.repeated_password) {
                errors.repeated_password = 'Wpisane hasła różnią się od siebie';
            }

            return errors;
        },
        onSubmit: (data) => {
            data.token = token;
            setFormData(data);
            userService.resetPassword(data).then(() => {
                // @ts-ignore
                toast.current.show({
                    severity: 'success',
                    detail: 'Hasło zostało zmienione',
                    life: 5000
                });

                window.open('/login', '_self')
                formik.resetForm();
            }).catch(() => {
                // @ts-ignore
                toast.current.show({
                    severity: 'warn',
                    detail: 'Hasło nie zostało zmienione, bądź link do zmiany hasła wygasł',
                    life: 5000
                });
            })
        }
    });

    return (
        <div
            className="col inline-flex justify-content-center align-items-center font-bold text-black border-round p-0">
            <div className="loginFormContainer">
                <h1>Ustaw nowe hasło</h1>
                <Toast ref={toast}/>
                <div className="formContainer">
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                        <div className="formRow">
                                <span className="p-float-label">
                                    <InputText id="password"
                                               name="password"
                                               type="password"
                                               value={formik.values.password}
                                               onChange={formik.handleChange}
                                               className={classNames({'p-invalid': isFormFieldValid('password')})}
                                               autoFocus/>

                                    <label htmlFor="password"
                                           className={classNames({'p-error': isFormFieldValid('password')})}>Hasło</label>
                                </span>
                            {getFormErrorMessage('password')}
                        </div>

                        <div className="formRow">
                                <span className="p-float-label">
                                    <InputText id="repeated_password"
                                               name="repeated_password"
                                               type="password"
                                               value={formik.values.repeated_password}
                                               onChange={formik.handleChange}
                                               className={classNames({'p-invalid': isFormFieldValid('repeated_password')})}
                                    />


                                    <label htmlFor="repeated_password"
                                           className={classNames({'p-error': isFormFieldValid('repeated_password')})}>Powtórz hasło</label>
                                </span>
                            {getFormErrorMessage('repeated_password')}
                        </div>

                        <div className="forgotPasswordRow">
                            <a href={'/login'}>Zaloguj się?</a>
                        </div>

                        <div className="submitButton">
                            <input type="hidden" name="token" id="token" value={''}/>
                            <Button type="submit" label="Zmień hasło"/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ResetPasswordPage;