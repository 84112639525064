import React, {useRef, useState} from "react";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {useFormik} from "formik";
import {classNames} from "primereact/utils";
import {Toast} from "primereact/toast";
import {userService} from "../../../../services/UserService";

interface ForgotFormInterface {
    email: string;
}

const ForgotPage = () => {
    const [formData, setFormData] = useState<ForgotFormInterface | any>({});
    const toast = useRef(null);

    // @ts-ignore
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    // @ts-ignore
    const getFormErrorMessage = (name) => {
        // @ts-ignore
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validate: (data: ForgotFormInterface) => {
            let errors: any = {};

            if (!data.email) {
                errors.email = 'Adres e-mail jest wymagany.';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                errors.email = 'Niepoprawny format adresu e-mail';
            }

            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            userService.rememberPassword(data).then(() => {
                window.open('/login', '_self')
                formik.resetForm();
            }).catch(() => {
            // @ts-ignore
                toast.current.show({
                severity: 'warn',
                detail: 'Resetowanie hasła nie powidło się',
                life: 5000
            });
            })
        }
    });

    return (
        <div
            className="col inline-flex justify-content-center align-items-center font-bold text-black border-round p-0">
            <div className="loginFormContainer">
                <Toast ref={toast}/>
                <h1>Przypomnij hasło</h1>
                <div className="subContainer">
                    <span>Wpisz swój <strong>adres e-mail</strong>, podany <br/> podczas rejestracji? </span>
                </div>

                <div className="formContainer">
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                        <div className="formRow">
                                <span className="p-float-label">
                                    <InputText id="email"
                                               name="email"
                                               value={formik.values.email}
                                               onChange={formik.handleChange}
                                               className={classNames({'p-invalid': isFormFieldValid('email')})}
                                               autoFocus/>
                                    <label htmlFor="email"
                                           className={classNames({'p-error': isFormFieldValid('email')})}>Email</label>
                                </span>
                            {getFormErrorMessage('email')}
                        </div>

                        <div className="forgotPasswordRow">
                            <a href={'/login'}>Zaloguj się?</a>
                        </div>

                        <div className="submitButton">
                            <Button type="submit" label="Zresetuj hasło"/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ForgotPage;