import LocationInterface from "../interfaces/LocationInterface";
import {LocationFilterInterface} from "../interfaces/LocationFilterInterface";
import {REQUEST_METHOD, sendFormDataRequest, sendRequest} from "../helpers";

export default class LocationService {
    async getLocations(filters: LocationFilterInterface): Promise<LocationInterface[]> {
        return sendRequest(`/location?filter=${JSON.stringify(filters)}`, {
            method: REQUEST_METHOD.GET
        })
    }

    async getLocation(id: string) {
        return sendRequest(`/location/${id}`, {
            method: REQUEST_METHOD.GET
        })
    }

    async create(data: any) {
        return sendRequest(`/location`, {
            body: data,
            method: REQUEST_METHOD.POST
        });
    };

    async delete(id: string) {
        return sendRequest(`/location/${id}`, {
            method: REQUEST_METHOD.DELETE
        });
    };

    async upload(id: string, data: any) {
        return sendFormDataRequest(`/location/${id}/upload`, data);
    };
}