import {Dialog} from "primereact/dialog";
import React, {useState} from "react";
import MiniMap from "../MiniMap/MiniMap";
import AddLocationForm from "../AddLocationForm/AddLocationForm";

const center = {
    lat: 51.8904457,
    lng: 19.8590717,
}

interface CreateFormModalProps {
    visible: boolean,
    setVisible: (state: boolean) => void
}

export function CreateFormModal(props: CreateFormModalProps) {
    const [position, setPosition] = useState(center);
    const [markerMoved, setMarkerMoved] = useState(false);

    const onHide = (state: boolean) => {
        props.setVisible(state)
    }

    return (
        <Dialog header="Dodaj nowy spacerek" draggable={false} visible={props.visible} breakpoints={{'960px': '75vw', '640px': '100vw'}}
                style={{width: '80vw'}}
                onHide={() => onHide(false)}>
            <div className="grid">
                <div className="col-12 md:col-6">
                    <MiniMap setMarkerMoved={setMarkerMoved} position={center} setPosition={setPosition}/>
                </div>

                <div className="col-12 md:col-6">
                    <AddLocationForm markerMoved={markerMoved} setVisible={props.setVisible} position={position} />
                </div>

            </div>
        </Dialog>
    )
}