import * as jwt from "jsonwebtoken";
import * as _ from "lodash";
import {REQUEST_METHOD, sendRequest} from "../helpers";

export interface AuthUserInterface {
    token: string;
}

export interface UserEntityInterface {
    email: string;
    first_name: string;
    password: string;
}

const login = (email: string, password: string): Promise<any> => {
    const requestOptions = {
        body: {email, password},
        method: REQUEST_METHOD.POST
    };

    return sendRequest('/auth/token', requestOptions)
        .then(response => {
            // login successful if there's a jwt token in the response
            if (response && response.access_token) {
                const dataFromToken: any = jwt.decode(response.access_token);

                const user = {
                    ...dataFromToken.user,
                    token: response.access_token,
                    validTo: response.valid_to
                };
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));

                return user;
            }

            return Promise.reject(response.statusText);
        })
        .catch((response: Response) => {
            if (response.status === 422) {
                return Promise.reject("Incorrect username or password.")
            }

            return Promise.reject(response.statusText);
        });
};

const refreshToken = (): Promise<AuthUserInterface> => {
    const requestOptions = {
        method: REQUEST_METHOD.POST
    };

    return sendRequest('auth/refresh_token', requestOptions)
        .then(response => {
            if (response && response.access_token) {

                const dataFromToken: any = jwt.decode(response.access_token);

                const user = {
                    ...dataFromToken.user,
                    token: response.access_token,
                    validTo: response.valid_to
                };
                localStorage.setItem('user', JSON.stringify(user));

                return user;
            }

            return Promise.reject(response.statusText);
        }).catch((response: Response) => {
            if (response.status === 422) {
                return Promise.reject("Incorrect username or password.")
            }

            return Promise.reject(response.statusText);
        });
};

const getAll = (page: number) => {
    return sendRequest(`users?page=${page}`)
        .then((response) => {
            return {
                items: response.items.map(mapApiToEntity),
                totalItems: response.totalItems
            };
        });
};

const create = (data: UserEntityInterface) => {
    return sendRequest(`/users`, {
        body: data,
        method: REQUEST_METHOD.POST
    }).then(mapApiToEntity);
};
// const update = (data: UserEntityInterface) => {
//     return sendRequest(`users/${data.id}`, {
//         body: data,
//         method: REQUEST_METHOD.PATCH
//     }).then(mapApiToEntity);
// };
const remove = (id: number) => {
    return sendRequest(`users/${id}`, {
        method: REQUEST_METHOD.DELETE
    }).then(() => id);
};

const rememberPassword = (email: any) => {
    return sendRequest(`/users/reset-password`, {
        body:email,
        method: REQUEST_METHOD.POST
    }).then((e) => {return e});
};

const resetPassword = (data: any) => {
    return sendRequest(`/users/reset-password/${data.token}`, {
        body:data,
        method: REQUEST_METHOD.POST
    }).then((e) => {return e});
};

const logout = () => {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
};

const getLoginUser = (): AuthUserInterface => {
    return JSON.parse(localStorage.getItem('user') as string);
};

const mapApiToEntity = (data: any): UserEntityInterface => {
    return _.mapKeys(data, (v, k) => _.camelCase(k)) as UserEntityInterface;
};

export const userService = {
    getLoginUser,
    logout,
    login,
    refreshToken,
    mapApiToEntity,
    create,
    rememberPassword,
    resetPassword,
    // update,
    remove,
    getAll
};