import {MapContainer, Marker, TileLayer, ZoomControl} from "react-leaflet";
import React, {useRef} from "react";

interface MiniMapInterface {
    position: {
        lat: number,
        lng: number
    },
    setMarkerMoved: (state: boolean) => void,
    setPosition: (position: {
        lat: number,
        lng: number
    }) => void
}

const center = {
    lat: 51.8904457,
    lng: 19.8590717,
}
export default function MiniMap(props: MiniMapInterface) {
    const markerRef = useRef(null)

    return (
        <div>
            <MapContainer style={{height: '60vh'}} zoomControl={false} id="mapDialogRoot" center={center} zoom={5}
                          minZoom={3}
                          maxZoom={18}
                          scrollWheelZoom={true}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker draggable={true}
                        position={props.position}
                        ref={markerRef}
                        eventHandlers={{
                            dragstart() {
                                props.setMarkerMoved(true);
                            },
                            dragend() {
                                const marker: any = markerRef.current
                                if (marker != null) {
                                    props.setPosition(marker.getLatLng())
                                }
                            },
                        }}/>
                ))
                <ZoomControl position="bottomright"/>
            </MapContainer>
        </div>
    );
}