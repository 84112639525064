import React, {useState} from 'react';
import './ActionBarStyles.scss'
import {CreateFormModal} from "../CreateFormDialog/CreateFormModal";
import ControlContainer from "../Panels/ControlContainer/ControlContainer";

interface ActionBarInterface {
    isLogged: boolean,
    setHasWater: (state: boolean) => void
    setHasForest: (state: boolean) => void
    setHasMeadow: (state: boolean) => void
    hasMeadow: boolean
    hasForest: boolean
    hasWater: boolean
}

export const ActionBar = (props: ActionBarInterface) => {
    const [visible, setVisible] = useState(false);

    return (
        <div className={'container flex justify-content-center align-items-center'}>
            {visible && <CreateFormModal visible={visible} setVisible={setVisible}/>}
            <div className="authorizedActionBar">
                <ControlContainer hasMeadow={props.hasMeadow} hasWater={props.hasWater} hasForest={props.hasForest}
                                  setHasWater={props.setHasWater} setHasMeadow={props.setHasMeadow}
                                  setHasForest={props.setHasForest} setVisible={setVisible}/>
            </div>
        </div>
    )
}