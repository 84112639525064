import * as  React from 'react';
import {RouteComponentProps} from 'react-router';

interface LoginPageState {
    email: string,
    password: string;
    submitted: boolean;
}

export class Page404 extends React.Component<RouteComponentProps<any>, LoginPageState> {

    public render() {

        return (
            <div className='page-404'>
                <h1>Oooops! Wygląda na to, że zbłądziłeś</h1>
            </div>
        )
    }
}
