import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import {InputTextarea} from "primereact/inputtextarea";
import {Message} from "primereact/message";
import {Checkbox} from "primereact/checkbox";
import {Button} from "primereact/button";
import React, {useState} from "react";
import {useFormik} from "formik";
import LocationService from "../../services/LocationService";
import './AddLocationForm.scss';

interface AddLocationForm {
    position: {
        lat: number,
        lng: number
    }
    setVisible: (state: boolean) => void,
    markerMoved: boolean
}

export default function AddLocationForm(props: AddLocationForm) {
    const [formData, setFormData] = useState({});
    const locationService = new LocationService();

    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            lat: null,
            lng: null,
            position: '',
            has_water: false,
            has_forest: false,
            has_meadow: false,
        },
        validate: (data) => {
            let errors: any = {};

            if (!data.name) {
                errors.name = 'Nazwa miejsca jest wymagana';
            }
            if (!data.description) {
                errors.description = 'Opis miejsca jest wymagana';
            }
            if (!data.position) {
                errors.position = 'DUPA';
            }
            if (!props.markerMoved) {
                errors.marker = 'Ruszaj nim';
            }
            return errors;
        },
        onSubmit: (data) => {
            Object.assign(data, props.position)
            setFormData(data);

            locationService.create(data).then(() => {
                formik.resetForm();
                props.setVisible(false)
            });
        }
    });

    formik.values.position = `${props.position.lat}, ${props.position.lng}`

    // @ts-ignore
    const isFormFieldValid = (name: string) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <div>
            <div className="card addLocationForm">
                <h3 className={'mt-0'}>Opis miejsce</h3>
                <form onSubmit={formik.handleSubmit} className="fluid">
                    <div className="p-field mb-5">
                                    <span className="p-float-label">
                                        <InputText id="name" name="name" value={formik.values.name}
                                                   onChange={formik.handleChange} autoFocus
                                                   className={classNames({'p-invalid': isFormFieldValid('name')})}/>
                                        <label htmlFor="name"
                                               className={classNames({'p-error': isFormFieldValid('name')})}><small>Nazwa*</small></label>
                                    </span> {getFormErrorMessage('name')}
                    </div>
                    <div className="p-field">
                                    <span className="p-float-label">
                                        <InputTextarea rows={6} id="description" name="description"
                                                       value={formik.values.description}
                                                       onChange={formik.handleChange}
                                                       className={classNames({'p-invalid': isFormFieldValid('description')})}/>
                                        <label htmlFor="name"
                                               className={classNames({'p-error': isFormFieldValid('description')})}><small>Opis*</small></label>
                                    </span> {getFormErrorMessage('description')}
                    </div>

                    <div className="p-field hidden">
                                    <span className="p-float-label">
                                        <InputText disabled={true} readOnly={true} id="position" name="position"
                                                   value={formik.values.position}
                                                   className={classNames({'p-invalid': isFormFieldValid('position')})}/>
                                        <label htmlFor="name"
                                               className={classNames({'p-error': isFormFieldValid('position')})}><small>Pozycja*</small></label>
                                    </span> {getFormErrorMessage('position')}
                    </div>

                    <div className="grid">
                        <div className="col mt-3">
                            <Message severity="info"
                                     text="Zaznacz poniższe jeżeli w dodawanym miejscu znajduje się las, łąka czy dostęp do wody"/>
                        </div>
                    </div>
                    <div className="grid my-3">
                        <div className="col">
                            <div
                                className="p-field-checkbox justify-content-center align-items-center flex flex-column mb-0">
                                <Checkbox inputId="has_water" name="has_water"
                                          checked={formik.values.has_water} onChange={formik.handleChange}
                                          className={classNames({'p-invalid': isFormFieldValid('accept')})}/>
                                <label htmlFor="has_water"
                                       className={classNames({'p-error': isFormFieldValid('has_water')})}>
                                    <small>Dostęp do wody</small>
                                </label>
                            </div>
                        </div>
                        <div className="col">
                            <div
                                className="p-field-checkbox justify-content-center align-items-center flex flex-column mb-0">
                                <Checkbox inputId="has_forest" name="has_forest"
                                          checked={formik.values.has_forest} onChange={formik.handleChange}
                                          className={classNames({'p-invalid': isFormFieldValid('accept')})}/>
                                <label htmlFor="has_forest"
                                       className={classNames({'p-error': isFormFieldValid('has_forest')})}>
                                    <small>Dostęp do lasu</small>
                                </label>
                            </div>
                        </div>
                        <div className="col">
                            <div
                                className="p-field-checkbox justify-content-center align-items-center flex flex-column mb-0">
                                <Checkbox inputId="has_meadow" name="has_meadow"
                                          checked={formik.values.has_meadow} onChange={formik.handleChange}
                                          className={classNames({'p-invalid': isFormFieldValid('accept')})}/>
                                <label htmlFor="has_meadow"
                                       className={classNames({'p-error': isFormFieldValid('has_meadow')})}>
                                    <small>Dostęp do łąki</small>
                                </label>
                            </div>
                        </div>
                    </div>

                    {!props.markerMoved && <Message severity="warn"
                                                    text="Przesuń pinezkę na mapie w wybrane miejsce aby móc zapisać lokalizację"/>}

                    <div className={"flex flex-row justify-content-center align-items-center mt-4"}>
                        <Button disabled={!props.markerMoved} type="submit" label="Dodaj spacerek"/>
                    </div>

                </form>
            </div>
        </div>
    )
}