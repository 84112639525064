import React, {useRef, useState} from 'react';
import {Toast} from 'primereact/toast';
import {FileUpload} from 'primereact/fileupload';
import {Button} from 'primereact/button';
import {Tooltip} from 'primereact/tooltip';
import {Tag} from 'primereact/tag';
import {Dialog} from "primereact/dialog";
import LocationService from "../../services/LocationService";
import LocationInterface from "../../interfaces/LocationInterface";

interface ImageUploader {
    location: LocationInterface,
    visible: boolean,
    setVisible: (state: boolean) => void
}

export default function ImageUploader(props: ImageUploader) {
    const [totalSize, setTotalSize] = useState(0);
    const toast = useRef(null);
    const fileUploadRef = useRef(null);
    const locationService = new LocationService();

    const onUpload = () => {
        // @ts-ignore
        toast.current.show({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
    }

    const onTemplateSelect = (e: any) => {
        // let _totalSize = totalSize;
        // e.files.forEach((file: any) => {
        //     _totalSize += file.size;
        // });
        //
        // setTotalSize(_totalSize);
    }

    const onTemplateUpload = (e: any) => {
        locationService.upload(props.location.id, e).then((r) => {
            // @ts-ignore
            toast.current.show({severity: 'info', summary: '', detail: 'Zdjęcia dodane!'});
            props.setVisible(false)
        })
    }

    const onTemplateRemove = (file: any, callback: any) => {
        setTotalSize(totalSize - file.size);
        callback();
    }

    const onTemplateClear = () => {
        setTotalSize(0);
    }

    const headerTemplate = (options: any) => {
        const {className, chooseButton, uploadButton, cancelButton} = options;
        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
            </div>
        );
    }

    const itemTemplate = (file: any, props: any) => {
        return (
            <div className="p-d-flex p-ai-center p-flex-wrap">
                <div className="p-d-flex p-ai-center" style={{width: '40%'}}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100}/>
                    <span className="p-d-flex p-dir-col p-text-left p-ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="p-px-3 p-py-2"/>
                <Button type="button" icon="pi pi-times"
                        className="p-button-outlined p-button-rounded p-button-danger p-ml-auto"
                        onClick={() => onTemplateRemove(file, props.onRemove)}/>
            </div>
        )
    }

    const emptyTemplate = () => {
        return (
            <div className="p-d-flex p-ai-center p-dir-col">
                <i className="pi pi-image p-mt-3 p-p-5" style={{
                    'fontSize': '5em',
                    borderRadius: '50%',
                    backgroundColor: 'var(--surface-b)',
                    color: 'var(--surface-d)'
                }}></i>
                <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)', textAlign: 'center'}}
                      className="p-my-5">
                    Przeciągnij i upuść zdjęcia w to miejsce aby je automatycznie zaczytać, bądź użyj niebieskiego guziczka by dodać, a następnie zielonego by wysłać
                </span>
            </div>
        )
    }

    const chooseOptions = {
        icon: 'pi pi-fw pi-images',
        iconOnly: true,
        className: 'custom-choose-btn p-button-rounded p-button-outlined'
    };
    const uploadOptions = {
        icon: 'pi pi-fw pi-cloud-upload',
        iconOnly: true,
        className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined'
    };
    const cancelOptions = {
        icon: 'pi pi-fw pi-times',
        iconOnly: true,
        className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'
    };

    const onHide = (state: boolean) => {
        props.setVisible(state)
    }

    return (
        <Dialog header="Dodaj zdjęcia" draggable={false} breakpoints={{'960px': '75vw'}} style={{width: '50vw'}} visible={props.visible}
                onHide={() => {
                    onHide(false)
                }}>
            <div>
                <Toast ref={toast}></Toast>

                <Tooltip target=".custom-choose-btn" content="Wybierz zdjęcia" position="bottom"/>
                <Tooltip target=".custom-upload-btn" content="Prześlij zdjęcia" position="bottom"/>
                <Tooltip target=".custom-cancel-btn" content="Wyczyść" position="bottom"/>

                <div className="card">
                    <FileUpload ref={fileUploadRef} name="demo[]"
                                url="https://primefaces.org/primereact/showcase/upload.php" multiple accept="image/*"
                                maxFileSize={1000000}
                                onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear}
                                onClear={onTemplateClear}
                                headerTemplate={headerTemplate} itemTemplate={itemTemplate}
                                emptyTemplate={emptyTemplate}
                                chooseOptions={chooseOptions} uploadOptions={uploadOptions}
                                cancelOptions={cancelOptions}/>
                </div>
            </div>
        </Dialog>
    )
}