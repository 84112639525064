import React, {useRef, useState} from 'react';
import './PlacePanelStyles.scss';
import LocationInterface from "../../../interfaces/LocationInterface";
import {Card} from "primereact/card";
import {BiImageAdd, BiWater, GiForestCamp, GiHighGrass, VscChromeClose} from "react-icons/all";
import {Button} from "primereact/button";
import {userHelper} from "../../../helpers/UserHelper";
import {confirmPopup} from 'primereact/confirmpopup';
import {Toast} from 'primereact/toast';
import LocationService from "../../../services/LocationService";
import ImageUploader from "../../ImageUploader/ImageUploader";

interface PlacePanelInterface {
    location: LocationInterface
    setLocation: any;
}

function PlacePanel(props: PlacePanelInterface) {
    const [uploader, setUploader] = useState<boolean>(false);
    const toast = useRef(null);
    const [visible, setVisible] = useState(false);

    const locationService = new LocationService();

    const accept = () => {
        locationService.delete(props.location.id).then(() => {
            // @ts-ignore
            toast.current.show({
                severity: 'success',
                summary: 'Usunięto',
                detail: 'Twoje miejsce na spacerek zostało usunięte',
                life: 3000
            });
        }).catch(e => {
            // @ts-ignore
            toast.current.show({
                severity: 'warn',
                summary: 'Wystąpił Problem',
                detail: 'Nie udało się usunąć Twojego spacerku. Prosimy spróbowac później',
                life: 3000
            });
        })
    };

    const deleteConfirmation = (event: any) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Czy napewno chcesz usunąć ten spacerek?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Tak',
            rejectLabel: 'Nie',
            accept
        });
    };

    const header =
        <div className={'placePanelHeader justify-content-between align-items-center'}>
            <span>{props.location.name}</span>
            <div className={'closeButton'} onClick={() => {
                props.setLocation(false)
            }}>
                <VscChromeClose size={20}/>
            </div>
        </div>

    const footer = <div>
        <div className="flex flex-row">

            {props.location.has_forest && <div data-tip="Lokalizacja posiada dostęp do lasu"
                                               className="col flex flex-column align-items-center justify-content-center">
                <GiForestCamp size={20}/>
                <span>Las</span>
            </div>}
            {props.location.has_meadow && <div data-tip="Lokalizacja posiada dostęp do łąki"
                                               className="col flex flex-column align-items-center justify-content-center">
                <GiHighGrass size={20}/>
                <span>Łąka</span>
            </div>}
            {props.location.has_water && <div data-tip="Lokalizacja posiada dostęp do wody"
                                              className="col flex flex-column align-items-center justify-content-center">
                <BiWater size={20}/>
                <span>Woda</span>
            </div>}

            <div className={'col flex'}>
                <Button onClick={() => {
                    window.open(`https://www.google.pl/maps/search/${props.location.lat}+${props.location.lng}/@${props.location.lat},${props.location.lng},14z`)
                }}>Nawiguj</Button>
            </div>
        </div>
        <div className="p-grid actionBar">
            {/*<div className="col flex p-flex-column p-ai-center p-jc-center">*/}
            {/*    <Button onClick={() => {*/}
            {/*        setVisible(true)*/}
            {/*        setUploader(true)*/}
            {/*    }} label="Submit" className={'p-button-sm p-button-outlined'}>*/}
            {/*        <span className={'buttonIcon'}><BiImageAdd size={15}/></span>*/}
            {/*    </Button>*/}
            {/*</div>*/}

            {userHelper.getUserToken().id === props.location.user_id &&
            <div className="col flex flex-column align-items-center justify-content-center">
                <Button onClick={deleteConfirmation} label="Usuń" icon="pi pi-trash" iconPos="right"
                        className={'p-button-sm p-button-outlined p-button-danger'}/>
            </div>}
            {uploader ? <ImageUploader location={props.location} setVisible={setVisible} visible={visible}/> : null}
        </div>
    </div>;

    return (
        <div className={'placePanel'}>
            <Toast position={'bottom-center'} baseZIndex={200000} ref={toast}/>
            <Card header={header} footer={footer}>
                <p className="p-m-0" style={{lineHeight: '1.5'}}>
                    {props.location.description}
                </p>
            </Card>
        </div>
    );
}


export default PlacePanel;